$select-tile-space: 20px !default;
$select-tile-ani: all 200ms ease-in-out;
$select-tile-height: 204px;
$select-tile-width: 160px;

.c-select-tile {
  &-fund-title {
    position: relative;
    font-size: 28px;

    &::after {
      @include translateX();
      @include border-radius(3px);

      position: absolute;
      bottom: 0;
      left: 50%;
      width: 40px;
      height: 5px;
      content: "";
      background-color: #f00;
    }

    &.-four {
      &::after {
        background-color: #0027ff;
      }
    }

    &.-five {
      &::after {
        background-color: #fcbacb;
      }
    }

    &.-six {
      &::after {
        background-color: #ff7474;
      }
    }
  }

  &-rate {
    font-size: 18px;
  }

  &-note {
    position: absolute;
    bottom: 20px;
    padding: 2px 10px;
    font-size: 11px;
    color: #d1d1d1;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    @include media-breakpoint-down("xs") {
      right: $spacing-base;
    }
  }

  &-item {
    @extend %a-tile;

    position: relative;
    bottom: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: $select-tile-width;
    max-width: 100%;
    height: $select-tile-height;
    margin-bottom: $select-tile-space;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    transition: $select-tile-ani;
    @include media-breakpoint-down("sm") {
      width: 130px;
      height: 270px;
      box-shadow: 0 1px 31px 0 rgba(44, 44, 49, 0.12);
    }
    @include media-breakpoint-down("xs") {
      width: 100px;
    }

    &:not(.-last) {
      // margin-right: ($select-tile-space*2);
      @include media-breakpoint-down("sm") {
        margin-right: 5px;
      }
    }

    &:hover {
      .c-select-tile-index {
        background: rgba($color-brand-primary, 0.2);
        border-color: rgba($color-brand-primary, 1);
      }
    }

    &.-active {
      transform: scale(1.05);
      @include media-breakpoint-down("sm") {
        transform: scale(1);
      }

      .c-select-tile-index {
        color: #fff;
        background: rgba($color-brand-primary, 1);
        border-color: rgba($color-brand-primary, 1);
        opacity: 1;
      }

      .c-select-tile-check-wrap {
        bottom: -2px;
      }

      &:hover {
        .c-select-tile-check-wrap {
          opacity: 0.7;
        }
      }
    }

    &.-multi {
      height: 224px;
      padding-bottom: 40px;
      overflow: hidden;
    }
  }

  &-index {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.3);
    opacity: 0.3;
    transition: $select-tile-ani;
    @include media-breakpoint-down("sm") {
      display: none;
    }
  }

  &-title {
    padding-bottom: 10px;
    margin: 0;
    font-size: 26px;
    @include media-breakpoint-down("sm") {
      padding: 0;
    }
  }

  &-subtitle {
    margin: 4px 0 0;
    font-size: 16px;
    font-weight: 600;
  }

  &-desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    @include media-breakpoint-down("xs") {
      padding-left: 10px;
      font-size: 14px;
      text-align: left;
    }
  }

  &-sub-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 400;
    @include media-breakpoint-down("xs") {
      font-size: 14px;
    }
  }

  &-icon {
    width: 40px;
    max-width: 100%;
    margin-top: -5px;
  }

  &-help {
    position: relative;
    top: -10px;
    width: $select-tile-width;
    max-width: 100%;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s ease-in-out;

    @include media-breakpoint-down("sm") {
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  &-check {
    width: 20px;
    height: 20px;
    margin-top: -2px;

    &-wrap {
      position: absolute;
      right: -2px;
      bottom: -25px;
      left: -2px;
      background: $color-brand-primary;
      transition: $select-tile-ani;
      transition: all 0.3s $ease-back;
    }
  }

  &.-bar-layout {
    .col-auto {
      width: 100%;
    }
  }

  &-radio {
    &.-bar-layout {
      @include media-breakpoint-down("xs") {
        .col-auto {
          width: 100%;

          .c-select-tile {
            &-title {
              margin-top: 0;
              font-size: 20px;
            }

            &-check {
              width: 20px;
              height: 20px;
              margin-top: -2px;

              &-wrap {
                top: -2px;
                right: -35px;
                bottom: -2px;
                left: initial;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 35px;
              }
            }

            &-item {
              flex-direction: row;
              align-items: center;
              width: 100%;
              height: auto;
              min-height: initial;
              padding: $spacing-base;
              margin-bottom: 0;

              &.-active {
                .c-select-tile-index {
                  color: #fff;
                  background: rgba($color-brand-primary, 1);
                  border-color: rgba($color-brand-primary, 1);
                }

                .c-select-tile-check-wrap {
                  right: -5px;
                }

                &:hover {
                  .c-select-tile-check-wrap {
                    opacity: 0.7;
                  }
                }
              }
            }

            &-index {
              margin: 0;
              margin-right: ($spacing-base / 2);
            }
          }
        }
      }

      .col-auto {
        .c-select-tile {
          &-item {
            height: auto;
            min-height: 150px;
          }
        }
      }

      &.-with-margin-bottom {
        .col-auto {
          .c-select-tile {
            &-item {
              min-height: 176px;
              margin-bottom: 20px;
              @include media-breakpoint-down("xs") {
                min-height: inherit;
              }
            }
          }
        }
      }
    }

    &.-small-title {
      .c-select-tile-title {
        font-size: 18px;
        font-weight: normal;
      }
    }
  }
}
