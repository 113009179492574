.c-asana-status {
  &-details {
    // test
  }
  &-history {
    padding: ($spacing-base / 2) 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  &-comment {
    @include gpuAccelerate();
    @include transition-this(max-height, 0.3s, ease-in-out);

    height: auto;
    max-height: 0;
    overflow: hidden;

    &.-reveal {
      max-height: 200px;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    .c-button {
      width: fit-content;
    }
  }
  &-side-row {
    display: flex;
    flex-direction: column;
    padding: $spacing-base;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include gpuAccelerate();
    @include transition-this(background-color, 0.1s, ease-in-out);
    &:hover {
      color: $white;
      background-color: $black;
    }
    &.-selected {
      color: $black;
      background-color: #ffe852;
    }
    h4 {
      margin: 0;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
}
